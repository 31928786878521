import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import Phone from "./components/PhoneAppel/Phone";
import Buggy from "./pages/Buggy";
import Yacht from "./pages/Yacht";
import Category from "./pages/Category";
import { brands, category } from "./data";
import Brand from "./pages/Brand";
import NavbarT from "./components/Navbar/NavbarT";
import JetPrivate from "./pages/JetPrivate";
import HotelReservation from "./pages/HotelReservation";
import BoatExcursion from "./pages/BoatExcursion";

function App() {
  return (
    <Router>
      <NavbarT />

      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Yacht" element={<Yacht />} />
        <Route path="/Buggy" element={<Buggy />} />
        <Route path="/JetPrivate" element={<JetPrivate />} />
        <Route path="/HotelReservation" element={<HotelReservation />} />
        <Route path="/BoatExcursion" element={<BoatExcursion />} />
        {/* Category */}
        {category.map((item) => {
          var fullPath = "category/" + item.title.replace(" ", "");
          return (
            <Route path={fullPath} element={<Category categ={item.title} />} />
          );
        })}

        {/* Brands */}
        {brands.map((item) => {
          var fullPath = "/brand/" + item.title.replace(" ", "");
          return (
            <Route path={fullPath} element={<Brand brand={item.title} />} />
          );
        })}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <Phone />
      <Whatsapp />
    </Router>
  );
}

export default App;
