import React from "react";
import styled from "styled-components";
import Border from "./Border";
import bsm from "../assets/bsm-white.svg";
import phone from "../assets/Phonec.svg";
// import fix from "../assets/fix.svg";
import mail from "../assets/Mail.svg";
import location from "../assets/Locationc.svg";
import fb from "../assets/fb.svg";
import insta from "../assets/insta.svg";
import ln from "../assets/linkedin.svg";
import { Link } from "react-router-dom";
import { brands } from "../data";
import { mobile } from "../Responsive";
import { GoTop } from "../controllers/GoTop";
const Container = styled.div`
  margin-top: 50px;
  width: 100%;
  background: #2b2a2b;
  /* height: 77vh; */
`;
const Top = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  ${mobile({
    gridTemplateColumns: "1fr",
  })}
`;
const Bottom = styled.div``;
const Left = styled.div`
  padding: 30px 40px;
  ${mobile({
    padding: 20,
  })}
`;
const Lefftt = styled.div`
  padding: 80px 40px 0 40px;
  ${mobile({
    textAlign: "center",
    padding: "30px 10px 0  10px ",
  })}
`;

const Right = styled.div`
  padding: 30px 40px;
  ${mobile({
    padding: 20,
  })}
`;
const Center = styled.div`
  padding: 30px 40px;
  ${mobile({
    padding: 20,
  })}
`;
const Title = styled.h2`
  color: #fff;
`;
const Desc = styled.p`
  padding-top: 40px;
  color: #fff;
  ${mobile({
    textAlign: "center",
  })}
`;
const Social = styled.div`
  padding-top: 40px;
  ${mobile({
    padding: "20px 0",
  })}
`;
const SocialList = styled.ul`
  ${mobile({
    paddingLeft: 20,
  })}
`;
const SocialItem = styled.a`
  padding: 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  span {
    transition: all 150ms;
  }
  &:hover span {
    color: #aaa;
  }
`;
const Span = styled.a`
  color: #fff;
  padding-left: 10px;
  font-size: 13px;
  text-decoration: none;
  &:hover {
    color: #dda76d;
  }
`;
const NavigationContainer = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  ${mobile({
    padding: "20px",
  })}
`;
const BrandsContainer = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  ${mobile({
    padding: "20px",
  })}
`;
const SocialNetworksContainer = styled.div`
  padding-top: 40px;
  text-align: center;
  ${mobile({
    padding: "0",
  })}
`;
const DIIIV = styled.div`
  padding: 10px 100px;
  ${mobile({
    padding: "10px 0",
    textAlign: "center",
    fontSize: 11,
  })}
`;
const HomeLink = styled.a`
  font-size: 9;
  color: #dda76b;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #dda76b;
  }
`;

const Footer = () => {
  const styleSheet = { color: "#fff", textDecoration: "none" };
  return (
    <Container>
      <Top>
        <Lefftt>
          <a href="/" style={{ textDecoration: "none" }}>
            <img src={bsm} style={{ width: 200, paddingLeft: 20 }} alt="logo" />
          </a>
          <Desc>
            High-end vehicle rental, private jet flight booking, yacht rental.
            Excelensia meets your mobility needs.
          </Desc>
        </Lefftt>
        <Left>
          <Title>Navigation</Title>
          <Border />
          <NavigationContainer>
            <Link to="/" style={styleSheet} onClick={GoTop}>
              {">Home"}
            </Link>
            <Link to="/Yacht" style={styleSheet} onClick={GoTop}>
              {">Yacht Rental"}
            </Link>
            <Link to="/Buggy" style={styleSheet} onClick={GoTop}>
              {">Buggy Rental"}
            </Link>
            <Link to="/category/All" style={styleSheet} onClick={GoTop}>
              {">Cars rental"}
            </Link>
            <Link to="/JetPrivate" style={styleSheet} onClick={GoTop}>
              {">Jet Private"}
            </Link>
            <Link to="/HotelReservation" style={styleSheet} onClick={GoTop}>
              {">Hotel Reservation"}
            </Link>
            <Link to="/BoatExcursion" style={styleSheet} onClick={GoTop}>
              {">Boat Excursion"}
            </Link>
            <Link to="/About" style={styleSheet} onClick={GoTop}>
              {">About us"}
            </Link>
            <Link to="/Contact" style={styleSheet} onClick={GoTop}>
              {">Contact us"}
            </Link>
          </NavigationContainer>
        </Left>
        <Center>
          <Title>Car brands</Title>
          <Border />
          <BrandsContainer>
            {brands.map((item) => {
              return (
                <Link
                  to={"/Brand/" + item.title.replace(" ", "")}
                  key={item.id}
                  style={{ color: "white" }}
                  onClick={GoTop}
                >
                  {item.title}
                </Link>
              );
            })}
          </BrandsContainer>
        </Center>
        <Right>
          <Title>Infos & Socials</Title>
          <Border />
          <Social>
            <SocialList>
              <SocialItem
                href="https://goo.gl/maps/qvPHk5gUVhamVNWv8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={location} style={{ width: 20 }} alt="location" />
                <Span>
                  Av. de las Naciones Unidas, 62, 29660 Marbella, Málaga, Spain
                </Span>
              </SocialItem>
              <SocialItem
                href="tel: +34635062736"
                target="_blank"
                rel="noreferrer"
              >
                <img src={phone} style={{ width: 20 }} alt="phone" />
                <Span>+34635062736</Span>
              </SocialItem>
              {/* <SocialItem>
                <img src={fix} style={{ width: 20 }} alt="fix" />
                <Span>+212661552233</Span>
              </SocialItem> */}
              <SocialItem
                href="mailto: bsm@bestservicesmarbella.com"
                target="_blank"
                rel="noreferrer"
              >
                <img src={mail} style={{ width: 20 }} alt="mail" />
                <Span>bsm@bestservicesmarbella.com</Span>
              </SocialItem>
            </SocialList>
          </Social>
          <SocialNetworksContainer>
            <h4 style={{ color: "#fff" }}>Contact via social networks</h4>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                paddingTop: 20,
              }}
            >
              <a
                href="https://www.facebook.com/103810255669358/posts/pfbid037z1CJn9kxp4fbF5ffsHBYsqT23B86xntynkYZeng1oJQrSZ2h4GyadbWPeJJ82RFl/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={fb} alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/bestservicesmarbella/?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <img src={insta} alt="Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/best-services-marbella"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ln} alt="linkedin" />
              </a>
            </div>
          </SocialNetworksContainer>
        </Right>
      </Top>
      <Bottom>
        <div style={{ height: 0.5, background: "#aaa", marginTop: 20 }} />
        <DIIIV>
          <span style={{ color: "#fff" }}>© Copyright 2020 </span>
          <HomeLink href="/">Best Services Marbella</HomeLink>
        </DIIIV>
      </Bottom>
    </Container>
  );
};

export default Footer;
