import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Slider from "../components/Slider/Slider";
import Category from "../components/Categorys/Category";
import Footer from "../components/Footer";
import Features from "../components/Features/Features";
import Brands from "../components/Brands/Brands";
import YachtSection from "../components/yachtSection/YachtSection";
import { mobile } from "../Responsive";

const Container = styled.div`
  /* height: 90vh; */
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled(motion.span)`
  font-family: Vilaka;
  font-size: 65px;
  font-weight: 400;
  padding-top: 100px;
  color: #2b2a2b;
  text-align: center;
  ${mobile({ fontSize: 55, padding: "70px 0 20px 0" })};
`;

const Home = () => {
  return (
    <Container>
      <Slider />
      <Title>Browse by Category</Title>
      <Category />

      <Title>Featured Cars</Title>
      <Features />

      <YachtSection />

      <Title>Car Brands</Title>
      <Brands />

      <Footer />
    </Container>
  );
};

export default Home;
