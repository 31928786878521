import React from "react";
import styled from "styled-components";
import bg from "../assets/bgBuggy.jpg";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import CardsBuggy from "../components/CardsBuggy/CardsBuggy";
import { mobile } from "../Responsive";

const Container = styled.div``;
const TopSection = styled(motion.div)`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 95)
    ),
    url(${bg}) no-repeat center;
  ${mobile({
    height: "55vh",
  })}
`;
const Title = styled(motion.h1)`
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  font-size: 70px;
  font-weight: 900;
  color: #fff;
  text-shadow: 6px 4px 9px #2b2a2b;
  ${mobile({
    fontSize: 37,
    textAlign: "center",
  })}
`;
const Button = styled(motion.button)`
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  text-transform: uppercase;
  padding: 10px 25px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  background: #dda76b;
  border: 2px solid #dda76b;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const MedSection = styled.div`
  padding: 50px 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 400px;
  ${mobile({
    gridTemplateColumns: "1fr",
    padding: 30,
    marginBottom: 100,
  })}
`;
const SubTitle = styled.h1`
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  margin-top: 50px;
  font-size: 35px;
  text-align: center;
  letter-spacing: 10px;
  color: #dda76b;
  ${mobile({
    fontSize: 20,
    width: "100%",
  })}
`;
const Description = styled.p`
  text-align: center;
  padding: 30px;
  font-style: italic;
  font-weight: 600;
`;

const Buggy = () => {
  return (
    <Container>
      <TopSection
        animate={{
          opacity: [0, 1],
        }}
        transition={{
          duration: 1.5,
        }}
      >
        <Title
          animate={{
            opacity: [0, 1],
            y: [-100, 0],
          }}
          transition={{
            duration: 2,
          }}
        >
          BUGGY RIDE IN THE DESERT
        </Title>
        <a href="http://wa.me/+34635062736" target="_blank" rel="noreferrer">
          <Button
            animate={{
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 2 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.99 }}
            transition={{ duration: 0.2 }}
          >
            Request Ride
          </Button>
        </a>
      </TopSection>
      <MedSection>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SubTitle>BUGGY RIDE IN THE DESERT</SubTitle>
          <Description>
            Rent one of many new Can-am buggies and explore the desert. Hourly
            rental is available.
            <br />
            It is the best you can find in Marbella and its surroundings.
          </Description>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../assets/quad.jpg")}
            alt="buggy"
            style={{
              width: "80%",
              height: "80%",
              objectFit: "cover",
              borderRadius: "60px 30px 60px 5px",
            }}
          />
        </div>
      </MedSection>
      <div>
        <CardsBuggy />
      </div>
      <Footer />
    </Container>
  );
};

export default Buggy;
