import React from "react";
import styled from "styled-components";
import { mobile } from "../../Responsive";
import { ListImages } from "./imgs/imgpath";

const Container = styled.div`
  padding: 50px 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  justify-content: center;
  ${mobile({
    gridTemplateColumns: "repeat(2, 1fr)",
    padding: 20,
  })}
`;
const Card = styled.div`
  overflow: hidden;
  height: 350px;
  background: #333;
  border-radius: 1rem;
  outline: 1px solid rgba(255, 255, 255, 0.5);
  outline-offset: -10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  ${mobile({
    height: 270,
  })}
`;
const Img = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.6;
  object-fit: cover;
  transition: transform 0.75s;
  &:hover {
    transform: scale(1.15);
  }
`;
// const Title = styled.h1`
//   position: absolute;
//   left: 25px;
//   bottom: 20px;
//   font-size: 25px;
//   font-family: Mitreba;
//   color: #fff;
//   text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
// `;
const Gallery = () => {
  return (
    <Container>
      {ListImages.map((item) => (
        <Card key={item.id}>
          {/* <Title>{item.title}</Title> */}
          <Img src={item.img} style={{ width: "100%" }} alt={item.title} />
        </Card>
      ))}
    </Container>
  );
};

export default Gallery;
