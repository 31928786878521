import React from "react";
import styled from "styled-components";
import bg from "../assets/jetbg.jpg";
import wave from "../assets/wavejet.svg";
import { mobile } from "../Responsive";
import { motion } from "framer-motion";
import imglf1 from "../assets/jet01.jpg";
import imglf2 from "../assets/jet02.jpg";
import imgrg1 from "../assets/jet03.jpg";
import imgrg2 from "../assets/jet04.jpg";
import godown from "../assets/godown.svg";
import Footer from "../components/Footer";

const Container = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
`;
const Top = styled.div`
  position: relative;
  height: 100vh;
  background: linear-gradient(
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    ),
    url(${bg});
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile({ height: "60vh" })}
`;
const Title = styled(motion.h1)`
  color: #fff;
  font-size: 80px;
  font-weight: 700;
  margin-top: 160px;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.84);
  ${mobile({ fontSize: 30, marginTop: 120 })}
`;

const Desc = styled(motion.p)`
  word-spacing: 2px;
  padding-top: 10px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  width: 40%;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.84);
  ${mobile({ fontSize: 14, width: "80%", paddingTop: 4 })}
`;
const Button = styled(motion.button)`
  width: 150px;
  padding: 8px 0;
  border: none;
  background: #dda76b;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 20px;
  -webkit-box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  transition: all 100ms;
  &:hover {
    box-shadow: none;
  }
  ${mobile({ fontSize: 16, width: 120, padding: "6px 0", marginTop: 0 })}
`;

const Main = styled.div`
  ${mobile({})}
`;
const MainTitle = styled.h1`
  font-family: Blastyes;
  text-align: center;
  font-size: 80px;
  margin: 100px 0;
  ${mobile({ fontSize: 50, margin: 50 })}
`;
const MainDesc = styled.p`
  padding: 0 200px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-style: italic;
  ${mobile({ padding: 20 })}
`;
const Grid = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 20px;
  height: 150vh;
  ${mobile({ flexDirection: "column" })}
`;
const LeftSide = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${mobile({ width: "100%" })}
`;
const RightSide = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${mobile({ width: "100%" })}
`;
const Img1 = styled.div`
  flex: 1;
`;
const Img2 = styled.div`
  flex: 1;
`;
const Img11 = styled.div`
  flex: 1;
`;
const Img22 = styled.div`
  flex: 1;
`;
const Wave = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6;
`;
const GoDown = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  z-index: 10;
  ${mobile({ display: "none" })}
`;

const JetPrivate = () => {
  return (
    <Container>
      <Top>
        <Title
          animate={{
            opacity: [0, 1],
            y: [-100, 0],
            transition: { duration: 2 },
          }}
        >
          STAY SAFE - FLY PRIVATE
        </Title>
        <Desc
          animate={{
            opacity: [0, 1],
            x: [100, 0],
            transition: { duration: 2 },
          }}
        >
          Best Services Marbella provides domestic and commercial air charter
          services, luxury travel around the world.
        </Desc>
        <a href="http://wa.me/+34635062736" target="_blank" rel="noreferrer">
          <Button
            animate={{
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 2 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.99 }}
            transition={{ duration: 0.2 }}
          >
            Reserve
          </Button>
        </a>
        <GoDown src={godown} alt="go down" style={{ bottom: 80 }} />
        <GoDown src={godown} alt="go down" style={{ bottom: 60 }} />
        <GoDown src={godown} alt="go down" style={{ bottom: 40 }} />
        <Wave src={wave} alt="wave" />
      </Top>
      <Main>
        <MainTitle>Fly worldwide</MainTitle>
        <MainDesc>
          Private jet rental services and chartered flights from leading
          providers in the Spain. Our excellence lies in our dedication to
          delivering seamless customers journeys. That is what sets us apart.
        </MainDesc>
        <Grid>
          <LeftSide>
            <Img1>
              <img src={imglf1} alt="jet" style={{ width: "100%" }} />
            </Img1>
            <Img2>
              <img src={imglf2} alt="jet" style={{ width: "100%" }} />
            </Img2>
          </LeftSide>
          <RightSide>
            <Img11>
              <img
                src={imgrg1}
                alt="jet"
                style={{ width: "100%", height: "100%" }}
              />
            </Img11>
            <Img22>
              <img
                src={imgrg2}
                alt="jet"
                style={{ width: "100%", height: "100%" }}
              />
            </Img22>
          </RightSide>
        </Grid>
      </Main>
      <Footer />
    </Container>
  );
};

export default JetPrivate;
