import React, { useRef, useState } from "react";
import styled from "styled-components";
import PhoneSvg from "../assets/phone.svg";
import EmailSvg from "../assets/email.svg";
import LocationSvg from "../assets/location.svg";
import TimeSvg from "../assets/time.svg";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { mobile } from "../Responsive";
import emailjs from "emailjs-com";

const Container = styled.div`
  padding-top: 60px;

  /* overflow: hidden; */
`;
const TopContainer = styled.div`
  padding: 0 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${mobile({
    padding: 20,
    gridTemplateColumns: "1fr",
  })}
`;
const LeftContainer = styled(motion.div)`
  padding: 40px 0;
`;
const Title = styled.h1`
  padding: 20px 0;
  font-family: Qhairo;
`;
const Desc = styled.p`
  font-size: 17px;
  font-family: "Montserrat";
  font-weight: 500;
  ${mobile({
    fontSize: 14,
  })}
`;
const Form = styled.form`
  padding: 10px 0;
  width: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${mobile({
    width: "100%",
  })}
`;
const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mobile({
    gridColumn: " 1/ span 2",
  })}
`;
const Label = styled.label`
  padding: 10px 0;
  font-weight: 500;
`;
const Input = styled.input`
  height: 40px;
  width: 200px;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  &:hover {
    border: 1px solid #dda76b;
  }

  &:focus {
    border-color: #dda76b;
    box-shadow: 0 0 0 0.25rem rgba(221, 167, 107, 0.25);
  }
  ${mobile({
    width: "100%",
  })}
`;

const RightContainer = styled(motion.div)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(motion.button)`
  margin-top: 20px;
  height: 40px;
  padding: 7px;
  border: none;
  background-color: #dda76b;
  color: #fff;
  border-radius: 0.25rem;
  width: 100%;
  font-weight: 500;
  ${mobile({
    width: "100%",
  })}
`;
const Textarea = styled.textarea`
  width: 90%;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  &:hover {
    border: 1px solid #dda76b;
  }
  &:focus {
    border: 1px solid #dda76b;
  }
`;
const Image = styled.div`
  ${mobile({
    width: "100%",
    textAlign: "center",
  })}
`;
const BottomContainer = styled.div`
  padding: 70px 150px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px;
  align-items: flex-start;
  margin-bottom: 50px;
  ${mobile({
    gridTemplateColumns: "1fr",
    padding: 50,
  })}
`;
const SocialItem = styled(motion.a)`
  cursor: pointer;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-decoration: none;
  &:hover > h4 {
    color: #dda76b;
  }
`;
const H4 = styled.h4`
  transition: all 300ms;
  font-size: 18px;
  text-align: center;
`;
const Iframe = styled.iframe`
  width: 450px;
  height: 550px;
  border: 3px solid #dda76b;
  border-radius: 10px;
  overflow: hidden;
  ${mobile({
    width: "80%",
    height: 370,
  })}
`;
const Input2 = styled.input`
  height: 40px;
  width: 100%;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 0.25rem;
  border: 1px solid #aaa;
  &:hover {
    border: 1px solid #dda76b;
  }

  &:focus {
    border-color: #dda76b;
    box-shadow: 0 0 0 0.25rem rgba(221, 167, 107, 0.25);
  }
  ${mobile({
    width: "100%",
  })}
`;

const Contact = () => {
  const formRef = useRef();
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fname && lname && email && message && tel) {
      emailjs
        .sendForm(
          "service_5wcwajp",
          "template_1vk2uah",
          formRef.current,
          "gbI2MffuthGtrKpbf"
        )
        .then(
          (result) => {
            console.log(result);
            alert("Message sended");
          },
          (error) => {
            console.log(error.text);
          }
        );

      Reset();
    }
  };

  const Reset = () => {
    document.getElementById("fname").value = "";
    document.getElementById("lname").value = "";
    document.getElementById("email").value = "";
    document.getElementById("message").value = "";
    document.getElementById("tel").value = "";
  };
  return (
    <Container>
      <TopContainer>
        <LeftContainer
          animate={{
            opacity: [0, 1],
            scale: [0, 1],
            transition: { duration: 1 },
          }}
        >
          <Title>Get in touch</Title>
          <Desc>Our friendly team would love to hear from you!</Desc>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormItem>
              <Label>First name</Label>
              <Input
                type="text"
                id="fname"
                name="user_fname"
                value={fname}
                onChange={(e) => {
                  setFName(e.target.value);
                }}
                placeholder="First name"
                className="form-control"
              />
            </FormItem>
            <FormItem>
              <Label>Last name</Label>
              <Input
                type="text"
                id="lname"
                name="user_lname"
                value={lname}
                onChange={(e) => {
                  setLName(e.target.value);
                }}
                placeholder="Last name"
                className="form-control"
                style={{ width: "100%" }}
              />
            </FormItem>
            <FormItem style={{ gridColumn: " 1/ span 2" }}>
              <Label>Email</Label>
              <Input2
                type="email"
                id="email"
                name="user_email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="you@company.com"
                className="form-control"
              />
            </FormItem>
            <FormItem style={{ gridColumn: " 1/ span 2" }}>
              <Label>Phone number</Label>
              <Input2
                type="tel"
                id="tel"
                name="user_tel"
                value={tel}
                onChange={(e) => {
                  setTel(e.target.value);
                }}
                placeholder="+212003300330"
                className="form-control"
              />
            </FormItem>
            <FormItem style={{ gridColumn: " 1/ span 2" }}>
              <Label>Message</Label>
              <Textarea
                id="message"
                name="message"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                style={{ width: "100%" }}
                placeholder="Message"
                rows={3}
                className="form-control"
              />
            </FormItem>
            <Button
              style={{ gridColumn: " 1/ span 2" }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.99 }}
              transition={{ duration: 0.2 }}
            >
              Send message
            </Button>
          </Form>
        </LeftContainer>
        <RightContainer
          animate={{
            opacity: [0, 1],
            scale: [0, 1],
            transition: { duration: 2 },
          }}
        >
          <Image>
            <Iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12830.481339769443!2d-4.9504501!3d36.4908851!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1a7e6c2dbd4bb5cb!2sBEST%20SERVICES%20MARBELLA!5e0!3m2!1sen!2sma!4v1659274907011!5m2!1sen!2sma"
              referrerpolicy="no-referrer-when-downgrade"
            ></Iframe>
          </Image>
        </RightContainer>
      </TopContainer>
      <BottomContainer>
        <SocialItem
          href="tel: +34635062736"
          target="_blank"
          rel="noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.99 }}
          transition={{ duration: 0.25 }}
        >
          <img src={PhoneSvg} style={{ width: 60 }} alt="phone number" />
          <H4>+34635062736</H4>
        </SocialItem>
        <SocialItem
          href="mailto: bsm@bestservicesmarbella.com"
          target="_blank"
          rel="noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.99 }}
          transition={{ duration: 0.25 }}
        >
          <img src={EmailSvg} style={{ width: 60 }} alt="email" />
          <H4>bsm@bestservicesmarbella.com</H4>
        </SocialItem>
        <SocialItem>
          <img src={TimeSvg} style={{ width: 60 }} alt="work-time" />
          <H4 style={{ textAlign: "center" }}>
            Monday - Saturday <br /> 10:00AM - 6:00PM
          </H4>
        </SocialItem>
        <SocialItem
          href="https://goo.gl/maps/qvPHk5gUVhamVNWv8"
          target="_blank"
          rel="noreferrer"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.99 }}
          transition={{ duration: 0.25 }}
        >
          <img src={LocationSvg} style={{ width: 60 }} alt="location" />
          <H4>Av. de las Naciones Unidas, 62, 29660 Marbella, Málaga, Spain</H4>
        </SocialItem>
      </BottomContainer>
      <Footer />
    </Container>
  );
};

export default Contact;
