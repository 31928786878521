import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logo } from "./marbella-logo.svg";
// import { ReactComponent as WhatsappSvg } from "../assets/whatsapp.svg";
// import { ReactComponent as DownSvg } from "../assets/down.svg";
// import { ReactComponent as ENSvg } from "../assets/EN.svg";
// import { ReactComponent as ARSvg } from "../assets/AR.svg";
// import { ReactComponent as ESSvg } from "../assets/ES.svg";
import { brands, category } from "../../data";
import { GoTop } from "../../controllers/GoTop";
import { mobile } from "../../Responsive";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";

const Bar = styled.div`
  width: 2.5px;
  height: 33px;
  border-radius: 50px;
  background-color: #aaa;
  margin: 0 100px 0 70px;
  ${mobile({ display: "none" })};
`;

const NavbarT = () => {
  const styleSheet = {
    background: "rgba(250, 250, 250, 0.60)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5.9px)",
    zIndex: 10000000000,
  };

  const clickLink = () => {
    document.getElementById("toggle").click();
    GoTop();
  };
  return (
    <Navbar fixed="top" expand="lg" style={styleSheet}>
      <Container style={{ background: "transparent" }}>
        <Navbar.Brand href="#home">
          <Link to="/">
            <Logo width={120} height={50} className="logo" />
          </Link>
        </Navbar.Brand>
        <Bar />
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ background: "transparent" }}
          id="toggle"
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{ textDecoration: "none" }}
        >
          <Nav
            className="me-auto"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
              gap: 15,
              fontWeight: 700,
            }}
          >
            <Nav.Link className="nav">
              <NavLink to="/" className="navlink" onClick={clickLink}>
                Home
              </NavLink>
            </Nav.Link>

            <NavDropdown
              title="Brands List"
              id="basic-nav-dropdown"
              className="navDropDownList"
              style={{ color: "#2b2a2b", background: "transparent" }}
            >
              {brands.map((item) => {
                var fullPath = "/brand/" + item.title.replace(" ", "");
                if (item.title !== "All") {
                  return (
                    <NavDropdown.Item href={fullPath}>
                      {/* <NavLink
                        to={fullPath}
                        key={item.id}
                        onClick={GoTop}
                        className="aa"
                        style={{
                          textDecoration: "none",
                          color: "#2b2a2b",
                          padding: 10,
                          transition: "all 100ms ease",
                          fontWeight: 500,
                        }}
                      > */}
                      {item.title}
                      {/* </NavLink> */}
                    </NavDropdown.Item>
                  );
                } else {
                  return null;
                }
              })}
            </NavDropdown>
            <NavDropdown
              title="Category"
              id="basic-nav-dropdown"
              style={{ color: "#2b2a2b" }}
            >
              {category.map((item) => {
                var fullPath = "/Category/" + item.title.replace(" ", "");
                if (item.title !== "All") {
                  return (
                    <NavDropdown.Item className="lss">
                      <NavLink
                        to={fullPath}
                        key={item.id}
                        onClick={clickLink}
                        style={{
                          textDecoration: "none",
                          color: "#2b2a2b",
                          padding: 10,
                          transition: "all 100ms ease",
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </NavLink>
                    </NavDropdown.Item>
                  );
                } else {
                  return null;
                }
              })}

              <NavDropdown.Divider />
              <NavDropdown.Item href="/category/All">All Cars</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="nav">
              <NavLink to="/Yacht" className="navlink" onClick={clickLink}>
                Yacht Rental
              </NavLink>
            </Nav.Link>
            <Nav.Link className="nav">
              <NavLink to="/Buggy" className="navlink" onClick={clickLink}>
                Buggy Rental
              </NavLink>
            </Nav.Link>
            <NavDropdown
              title="Other Services"
              id="basic-nav-dropdown"
              style={{ color: "#2b2a2b" }}
            >
              <NavDropdown.Item className="lss">
                <NavLink
                  to="/JetPrivate"
                  onClick={clickLink}
                  style={{
                    textDecoration: "none",
                    color: "#2b2a2b",
                    padding: 10,
                    transition: "all 100ms ease",
                    fontWeight: 500,
                  }}
                >
                  Jet Private
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink
                  to="/HotelReservation"
                  onClick={clickLink}
                  style={{
                    textDecoration: "none",
                    color: "#2b2a2b",
                    padding: 10,
                    transition: "all 100ms ease",
                    fontWeight: 500,
                  }}
                >
                  Hotel Reservation
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink
                  to="/BoatExcursion"
                  onClick={clickLink}
                  style={{
                    textDecoration: "none",
                    color: "#2b2a2b",
                    padding: 10,
                    transition: "all 100ms ease",
                    fontWeight: 500,
                  }}
                >
                  Boat Excursion
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="nav">
              <NavLink to="/About" className="navlink" onClick={clickLink}>
                About
              </NavLink>
            </Nav.Link>
            <Nav.Link className="nav">
              <NavLink to="/Contact" className="navlink" onClick={clickLink}>
                Contact
              </NavLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarT;
