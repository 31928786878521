import React from "react";
import styled from "styled-components";
import { mobile } from "../Responsive";

const Container = styled.a`
  background-color: #f9f9f9;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  height: 400px;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 100px;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  ${mobile({
    gridTemplateColumns: "1fr",
    height: "auto",
  })}
`;
const Content = styled.div`
  order: ${(props) => (props.ltr ? 1 : 3)};
  padding: 30px 45px;
  height: 100%;
  ${mobile({
    order: 3,
  })}
`;
const Image = styled.div`
  overflow: hidden;
  order: ${(props) => (props.ltr ? 3 : 1)};
  height: 400px;
  ${mobile({
    order: 1,
  })}
`;
const SubTitle = styled.h1`
  padding: 20px 0;
  font-size: 50px;
  font-family: Mitreba;
`;
const SVG = styled.svg`
  margin-left: -60px;
  margin-left: ${(props) => (props.ltr ? 60 : -60)}px;
  order: 2;
  transform: rotate(${(props) => (props.ltr ? 180 : 0)}deg);
  ${mobile({
    order: 2,
    display: "none",
  })}
`;

const Section = (props) => {
  return (
    <Container
      className="section"
      href="http://wa.me/+34635062736"
      target="_blank"
      rel="noreferrer"
    >
      <Image ltr={props.ltr}>
        <img
          src={props.img}
          style={{ width: "100%", height: "100%" }}
          className="imgSection"
          alt="water sport"
        />
      </Image>
      <SVG
        ltr={props.ltr}
        locale="fr"
        class="About__StyledDivider-xii8up-2 kJiYaz"
        width="60"
        height="100%"
        viewBox="0 0 60 1000"
        fill="#F9F9F9"
        preserveAspectRatio="none"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M85.5 1079.95C43.7072 966.929 19.9593 852.301 14.5 737.24C8 600.336 29.872 512.409 41.5 424.739C59.279 290.7 60.81 99.3082 -16.5 -142.448H85.5L85.5 1079.95Z"
            fill="#F9F9F9"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="1000"
              height="60"
              fill="#F9F9F9"
              transform="translate(0 1000) rotate(-90)"
            ></rect>
          </clipPath>
        </defs>
      </SVG>
      <Content ltr={props.ltr}>
        <SubTitle>{props.title}</SubTitle>
        <p>{props.desc}</p>
      </Content>
    </Container>
  );
};

export default Section;
