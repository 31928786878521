export const brands = [
  {
    id: 1,
    title: "BMW",
  },
  {
    id: 2,
    title: "Audi",
  },
  {
    id: 3,
    title: "Range Rover",
  },
  {
    id: 4,
    title: "Jeep",
  },
  {
    id: 5,
    title: "Mercedes",
  },
  {
    id: 6,
    title: "Porsche",
  },
  {
    id: 7,
    title: "Fiat",
  },
  {
    id: 8,
    title: "VW",
  },
  {
    id: 9,
    title: "Jaguar",
  },
  {
    id: 10,
    title: "Ford",
  },
  {
    id: 11,
    title: "Toyota",
  },
  {
    id: 12,
    title: "Citroen",
  },
  {
    id: 13,
    title: "Maserati",
  },
  {
    id: 14,
    title: "Opel Corsa",
  },
  {
    id: 15,
    title: "Renault",
  },
  {
    id: 16,
    title: "Peugeot",
  },
  {
    id: 16,
    title: "MINI One",
  },
];
export const category = [
  {
    id: 1,
    title: "Berline",
    img: "./img1.jpg",
  },
  {
    id: 2,
    title: "Luxury",
    img: "./img2.jpg",
  },
  {
    id: 3,
    title: "Convertible",
    img: "./img3.jpg",
  },
  {
    id: 4,
    title: "SUV",
    img: "./img4.jpg",
  },
  {
    id: 6,
    title: "MultiPlaces",
    img: "./img5.jpg",
  },
  {
    id: 7,
    title: "All",
    img: "./img5.jpg",
  },
];
