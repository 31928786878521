import React from "react";
import styled from "styled-components";
import bg from "../assets/car2.jpg";
import Footer from "../components/Footer";
import Data from "../data.json";
import CartB from "../components/CartB";
import { mobile } from "../Responsive";
// import { FormSelect } from "react-bootstrap";

const Container = styled.div``;
const MainContent = styled.div`
  padding: 90px;
  gap: 70px 0;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  ${mobile({
    gridTemplateColumns: "repeat(1, 1fr)",
    padding: 0,
    gap: "50px 0",
  })}
`;
const TopContainer = styled.div`
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 95)
    ),
    url(${bg}) no-repeat center;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({
    height: "30vh",
  })}
`;
const Title = styled.h1`
  font-size: 70px;
  font-weight: 900;
  color: #fff;
  text-shadow: 6px 4px 9px #2b2a2b;
  ${mobile({
    fontSize: 37,
  })}
`;
// const SelectionContainer = styled.div`
//   margin-top: 80px;
//   display: flex;
//   padding: 0 400px;
//   align-items: center;
//   flex-direction: column;
//   ${mobile({
//     flexDirection: "column",
//     gap: 15,
//     padding: "60px 30px",
//     alignItems: "center",
//     marginTop: 0,
//   })}
//   .form-control:focus {
//     border-color: #dda76b;
//     /* box-shadow: 0 0 0 0.25rem rgba(221, 167, 107, 0.25); */
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
//       0 0 8px rgba(221, 167, 107, 0.6);
//   }
// `;
// const FromTitle = styled.h4`
//   padding-bottom: 30px;
//   ${mobile({
//     paddingBottom: 0,
//     fontSize: 17,
//   })}
// `;

const Category = (props) => {
  // const [country, setCountry] = useState("France");
  return (
    <Container>
      <TopContainer>
        <Title>{props.categ} Cars Lease</Title>
      </TopContainer>
      {/* <SelectionContainer>
        <FromTitle>Choose your country:</FromTitle>
        <FormSelect
          className="form-control"
          style={{
            // borderRadius: 0,
            padding: 10,
          }}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="">-- Choose your country --</option>
          <option value="France">France</option>
          <option value="Spain">Spain</option>
        </FormSelect>
      </SelectionContainer> */}
      <MainContent>
        {Data.map((item) => {
          if (props.categ === item.categ || props.categ === "All") {
            return (
              <CartB
                key={item.id}
                img={item.imageUrl}
                Name={item.title}
                seater={item.maxPassengers}
                doors={item.doors}
                categ={item.categ}
                mode={item.automatic}
                air={item.airCondition}
              />
            );
          } else {
            return null;
          }
        })}
      </MainContent>
      <Footer />
    </Container>
  );
};

export default Category;
