import React from "react";
import styled from "styled-components";

const Container = styled.a`
  position: fixed;
  bottom: 25px;
  right: 30px;
  background: transparent;
  width: 60px;
  height: 60px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const Whatsapp = () => {
  return (
    <Container
      className="watsContainer"
      href="http://wa.me/+34635062736"
      target="_blank"
      rel="noreferrer"
    >
      <img src={require("./w.gif")} alt="whatsapp" width={80} height={80} />
    </Container>
  );
};

export default Whatsapp;
