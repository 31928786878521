import React from "react";
import styled from "styled-components";
import bg from "../assets/yachtS.jpg";
import { motion } from "framer-motion";
import Footer from "../components/Footer";
import Section from "../components/Section";
import img1 from "../assets/jetSki.jpg";
import img2 from "../assets/Seabob.jpg";
import Gallery from "../components/Gallery/Gallery";
import { mobile } from "../Responsive";

const Container = styled.div``;
const TopSection = styled(motion.div)`
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 95)
    ),
    url(${bg}) no-repeat center;
  ${mobile({
    height: "55vh",
  })}
`;
const Title = styled(motion.h1)`
  font-size: 70px;
  font-style: italic;
  font-weight: 900;
  color: #fff;
  text-shadow: 6px 4px 9px #2b2a2b;
  ${mobile({
    fontSize: 40,
    textAlign: "center",
  })}
`;
const Desc = styled(motion.p)`
  text-align: center;
  width: 600px;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  color: #ffe0b2;
  padding: 10px;
  text-shadow: 6px 4px 9px #2b2a2b;
  ${mobile({
    fontSize: 20,
    width: "auto",
  })}
`;
const Button = styled(motion.button)`
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 10px 25px;
  margin-top: 40px;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  color: #fff;
  background: #dda76b;
  border: 2px solid #dda76b;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
const MedSection = styled.div`
  padding: 200px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mobile({
    padding: "50px 20px",
  })}
`;
const DescG = styled.p`
  text-align: center;
  padding: 20px 200px;
  ${mobile({
    padding: "10px 20px",
    fontSize: 12,
  })}
`;
const Yacht = () => {
  return (
    <Container>
      <TopSection
        animate={{
          opacity: [0, 1],
        }}
        transition={{
          duration: 1.5,
        }}
      >
        <Title
          animate={{
            opacity: [0, 1],
            y: [-100, 0],
          }}
          transition={{
            duration: 2,
          }}
        >
          ENJOYMENT VIP YACHT
        </Title>
        <Desc
          animate={{
            opacity: [0, 1],
            y: [50, 0],
          }}
          transition={{
            duration: 2,
          }}
        >
          Enjoy Your Summer
        </Desc>
        <a href="http://wa.me/+34635062736" target="_blank" rel="noreferrer">
          <Button
            animate={{
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 2 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.99 }}
            transition={{ duration: 0.2 }}
          >
            Request Ride
          </Button>
        </a>
      </TopSection>
      <MedSection>
        <Title
          style={{
            textShadow: "none",
            color: "#dda76b",
            padding: 0,
            fontFamily: "Mitreba",
          }}
        >
          Water Sports Equipement
        </Title>
        <Section
          ltr={false}
          title="Jet Ski"
          desc=" Get the best jet-skiing experience in whole Dubai. We propose some of the finest jet ski models in the market. We believe that the natural way of discovering Dubai’s most famous places of interest is on a Jet Ski, as the whole city revolves around the Persian Gulf. Discover below the guided tours that we offer to our customers.
          "
          img={img1}
        />
        <Section
          ltr={true}
          title="Seabob"
          desc=" The Seabob is the world’s fastest underwater sports vehicle. The Seabob is a multi-purpose, lightweight, and fast-moving device. It weighs between 29 and 35 kg and has a top speed of 13 to 20 km/h. A single battery charge lasts around an hour, and the Seabob can go down to 40 metres (131 feet) below the surface."
          img={img2}
        />
      </MedSection>
      <Title
        style={{
          textShadow: "none",
          color: "#dda76b",
          marginBottom: 0,
          marginTop: 0,
          textAlign: "center",
          fontFamily: "Mitreba",
        }}
      >
        GALLERY
      </Title>
      <DescG>
        A magnificent performance craft, the 95 FT Sunseeker Black PREDATOR
        exemplifies the latest cutting-edge design and innovative build
        processes, showcasing the exciting new design direction. Showing off a
        streamlined yet spacious elegance, the Black PREDATOR combines a
        superyacht feel with many practical advantages, demonstrating a clever
        use of space that gives even greater flexibility and freedom onboard.
        Fully equipped for luxury entertaining, this is yet another stunning
        illustration of striking lines and an open plan layout, combining a
        superyacht feel with all the practical advantages of a yacht in this
        size category.
      </DescG>
      <Gallery />
      <Footer />
    </Container>
  );
};

export default Yacht;
