import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import bg from "../assets/boatbg.jpg";
import { mobile } from "../Responsive";
import vd from "../assets/video.mp4";

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`;
const Top = styled.div`
  position: absolute;
  top: 15%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.95)
    ),
    url(${bg}); */
  background-position: bottom;
  background-size: cover;
  width: 100%;
`;
const Title = styled(motion.h1)`
  color: #fff;
  font-size: 80px;
  font-style: italic;
  font-weight: 700;
  margin-top: 160px;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.84);
  text-align: center;
  text-transform: uppercase;
  ${mobile({ fontSize: 30, marginTop: 120 })}
`;

const Desc = styled(motion.p)`
  word-spacing: 2px;
  padding-top: 10px;
  font-weight: 600;
  font-style: italic;
  color: #fff;
  text-align: center;
  width: 80%;
  font-size: 18px;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.84);
  ${mobile({ fontSize: 14, width: "80%", paddingTop: 4 })}
`;
const Button = styled(motion.button)`
  width: 150px;
  padding: 8px 0;
  border: none;
  background: #dda76b;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 1px;
  margin-top: 20px;
  -webkit-box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  transition: all 100ms;
  &:hover {
    box-shadow: none;
  }
  ${mobile({ fontSize: 16, width: 120, padding: "6px 0", marginTop: 0 })}
`;
const Video = styled.video`
  position: relative;
  z-index: 1;
  width: 100%;
  ${mobile({ width: "auto", height: "100%" })}
`;

const BoatExcursion = () => {
  return (
    <Container>
      <Video loop muted autoPlay>
        <source src={vd} type="video/mp4" />
      </Video>
      <Top>
        <Title
          animate={{
            opacity: [0, 1],
            y: [-100, 0],
            transition: { duration: 0.8 },
          }}
        >
          enjoy your trip
        </Title>
        <Desc
          animate={{
            opacity: [0, 1],
            scale: [0.5, 1],
            transition: { duration: 0.9 },
          }}
        >
          Don't spend your vacation waiting around. Book skip the line tickets
          and beat the crowds. Skip the lines at the world's most popular
          attractions when you book tickets in advance.
        </Desc>
        <a href="http://wa.me/+34635062736" target="_blank" rel="noreferrer">
          <Button
            animate={{
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 1 },
            }}
          >
            Reserve
          </Button>
        </a>
      </Top>
    </Container>
  );
};

export default BoatExcursion;
