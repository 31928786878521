import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import img3 from "./img3.jpg";
import img4 from "./img4.jpg";
import img5 from "./img5.jpg";
import img6 from "./img6.jpg";
import img7 from "./img7.jpg";
import img8 from "./img8.jpg";
export const ListImages = [
  {
    id: 1,
    img: img1,
    title: "first image",
  },
  {
    id: 2,
    img: img2,
    title: "first image",
  },
  {
    id: 3,
    img: img3,
    title: "first image",
  },
  {
    id: 4,
    img: img4,
    title: "first image",
  },
  {
    id: 5,
    img: img5,
    title: "first image",
  },
  {
    id: 6,
    img: img6,
    title: "first image",
  },
  {
    id: 7,
    img: img7,
    title: "first image",
  },
  {
    id: 8,
    img: img8,
    title: "first image",
  },
];
