import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mobile } from "../../Responsive";
import yacht from "./imgs/yacht.jpg";

const Container = styled.div`
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.5)
    ),
    url(${yacht}) no-repeat center;
  background-size: cover;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ width: "100%", marginTop: 50, height: "50vh" })}
`;
const Title = styled.h1`
  font-size: 50px;
  color: #dda76b;
  letter-spacing: 40px;
  ${mobile({ fontSize: 20, letterSpacing: 15 })}
`;
const Desc = styled.span`
  color: #fff;
  font-size: 30px;
  margin-top: 16px;
  font-weight: 700;
  ${mobile({ fontSize: 16 })}
`;
const Button = styled(motion.button)`
  background: transparent;
  text-transform: uppercase;
  padding: 10px;
  border: 4px solid #dda76b;
  color: #dda76b;
  font-size: 16px;
  font-weight: 700;
  margin-top: 40px;
  ${mobile({ fontSize: 14, borderWidth: 3 })}
`;

const YachtSection = () => {
  return (
    <Container>
      <Title>YACHT RENTAL</Title>
      <Desc>LUXURY VIP YACHT</Desc>
      <a href="/Yacht">
        <Button
          whileHover={{ scale: 1.15 }}
          whileTap={{ scale: 0.99 }}
          transition={{ duration: 0.3 }}
        >
          Check it out
        </Button>
      </a>
    </Container>
  );
};

export default YachtSection;
