import React from "react";
import styled from "styled-components";
import bg from "../assets/hotelbg.jpg";
import mainbg from "../assets/bg.png";
import { motion } from "framer-motion";
import logoBlack from "../assets/marbella-black.svg";
import hotelimg1 from "../assets/hotel111.jpg";
import hotelimg2 from "../assets/hotel22.jpg";
import hotelimg3 from "../assets/hotel333.jpg";
import Footer from "../components/Footer";
import { mobile } from "../Responsive";

const Container = styled.div`
  height: 100vh;
  display: grid;
  place-items: center;
`;
const Top = styled.div`
  height: 100vh;
  background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.9)
    ),
    url(${bg});
  background-position: bottom;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  ${mobile({
    alignItems: "center",
    padding: 10,
  })}
`;
const Title = styled(motion.h1)`
  color: #dda76b;
  font-size: 80px;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  ${mobile({
    fontSize: 40,
    textAlign: "center",
  })}
`;
const Desc = styled(motion.p)`
  word-spacing: 2px;
  padding-top: 10px;
  padding-left: 20px;
  font-weight: 600;
  width: 50%;
  color: #fff;
  text-shadow: 0.3125rem 0.25rem 0.5rem rgba(0, 0, 0, 0.84);
  ${mobile({
    width: "100%",
    textAlign: "center",
  })}
`;
const Button = styled(motion.button)`
  width: 150px;
  padding: 8px 0;
  border: none;
  background: #dda76b;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 50px 0 0 20px;
  -webkit-box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.63);
  transition: all 100ms;
  &:hover {
    box-shadow: none;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${mainbg});
  ${mobile({})}
`;
const MainTitle = styled.h1`
  font-family: Blastyes;
  text-align: center;
  padding: 6.25rem 0 3.125rem 0;
  font-size: 3.125rem;
  ${mobile({ fontSize: 40, padding: 20 })}
`;
const Logo = styled.div`
  padding: 4.375rem 0 1.875rem 0;
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  height: 100px;
  ${mobile({ height: 60 })}
`;

const MainDesc = styled.p`
  padding: 0 150px;
  margin-bottom: 60px;
  text-align: center;
  ${mobile({ padding: 20 })}
`;
const MainContent = styled.div`
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MainCart = styled.div`
  position: absolute;
  background-color: rgba(221, 167, 107, 0.8);
  width: 300px;
  padding: 20px;
  top: 10%;
  left: 10%;
  ${mobile({ position: "static", width: "100%" })}
`;
const CartTitle = styled.h1`
  color: #fff;
  font-size: 60px;
  ${mobile({ fontSize: 35 })}
`;
const CartDesc = styled.p`
  color: #fff;
  font-size: 18px;
  ${mobile({ fontSize: 14 })}
`;
const Bottom = styled.div`
  font-family: Blastyes;
  font-size: 40px;
  padding: 50px 0;
  ${mobile({
    fontSize: 27,
  })}
`;
const Img1 = styled.img`
  ${mobile({ width: "100%" })}
`;
const Img2 = styled.img`
  width: 100%;
  margin-top: 50px;
  z-index: 1;
`;
const Img3 = styled.img`
  width: 100%;
  height: 100%;
`;
const Div1 = styled.div`
  display: flex;
  justify-content: center;
`;
const Div2 = styled.div`
  width: 35%;
`;
const Div3 = styled.div`
  width: 200px;
  height: 280px;
  background: #dda76b;
  z-index: 0;
  margin-left: -30px;
  margin-top: -250px;
  ${mobile({ display: "none" })}
`;
const Div4 = styled.div`
  margin-top: -100px;
  border: 4px solid #fff;
  margin-left: 50px;
  width: 35%;
  height: 500px;
  ${mobile({ height: 150, marginTop: -30 })}
`;

const HotelReservation = () => {
  return (
    <Container>
      {/* <video autoplay muted loop id="myVideo">
        <source src={vd} type="video/mp4" />
      </video> */}
      <Top>
        <Title
          animate={{
            opacity: [0, 1],
            y: [-100, 0],
            transition: { duration: 1 },
          }}
        >
          Get Comfort in your Life
        </Title>
        <Desc
          animate={{
            opacity: [0, 1],
            transition: { duration: 2 },
          }}
        >
          We invite you to fall in love with this corner of the world, with its
          mild microclimate, sea air and mountain views. He gets to know a
          historic estate called Santa Margarita that has stood the test of
          time, poignantly blending tradition and innovation while retaining its
          original charm.
        </Desc>
        <a href="http://wa.me/+34635062736" target="_blank" rel="noreferrer">
          <Button
            animate={{
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 1 },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.99 }}
            transition={{ duration: 0.2 }}
          >
            Reserve
          </Button>
        </a>
      </Top>
      <Main>
        <Logo>
          <Image src={logoBlack} alt="marbela logo" />
        </Logo>
        <MainTitle>Welcom to best services marbella</MainTitle>
        <MainDesc>
          We invite you to fall in love with this corner of the world, with its
          mild microclimate, sea air and mountain views. She gets to know a
          historic estate called Santa Margarita that has lasted over time,
          combining Tradition and enchantment in a moving way, while maintaining
          its original charm. A place full of traditions that encompasses the
          Andalusian character: we invite you to follow our rhythm, which
          promotes the important things in life, family, community, laughter and
          unity.
        </MainDesc>
        <MainContent>
          <MainCart>
            <CartTitle>JUNTO AL MAR</CartTitle>
            <CartDesc>
              Un lugar legendario con su emblemática palapa y el mítico
              pantalán. En su restaurante junto a la piscina se sirve pescado
              fresco del día para disfrutar de un almuerzo relajado todo el año
              y tomar agradables baños de sol.
            </CartDesc>
          </MainCart>
          <Img1 src={hotelimg1} alt="hotel" />
          <Div1>
            <Div2>
              <Img2 src={hotelimg2} alt="hotel" />
              <Div3 />
            </Div2>
            <Div4>
              <Img3 src={hotelimg3} alt="hotel" />
            </Div4>
          </Div1>
        </MainContent>
      </Main>
      <Bottom>Un lugar para todas las temporadas</Bottom>
      <Footer />
    </Container>
  );
};

export default HotelReservation;
